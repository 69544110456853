<template lang="pug">
  .login
    .half-container.white
    .half-container.red
    .container-centered
        .half-form.half-form-red
          img.logo-mobile(:src='LoboBla')
          h4 Iniciar sesión
          b-form(@submit.prevent="procesarFormulario")
            b-form-group(label='Número de Documento' label-for='form.dni')
              b-form-input(v-model.trim='$v.form.dni.$model' type='text' placeholder='' required='')
              .error(v-if="(!$v.form.dni.required && $v.form.dni.$anyDirty)") Campo obligatorio
              .error(v-if="!$v.form.dni.numeric && $v.form.dni.$anyDirty") Número de documento inválido
            b-form-group(label='Contraseña:' label-for='form.pass')
              b-form-input(v-model.trim='$v.form.pass.$model' placeholder='' required='', type='password')
              .error(v-if="(!$v.form.pass.required && $v.form.pass.$anyDirty)") Campo obligatorio
            b-button.btn(type='submit') INGRESAR
        .half-form.half-form-white
          img.logo(:src='Logo')
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import LogoBlanco from '../assets/images/logo-blanco-color.png'
import LogoFull from '../assets/images/logo-full-color.png'
export default {
  data () {
    return {
      Logo: LogoFull,
      LoboBla: LogoBlanco,
      form: {
        dni: '',
        pass: ''
      }
    }
  },
  validations: {
    form: {
      dni: {
        required,
        numeric
      },
      pass: {
        required
      }
    }
  },
  methods: {
    ...mapActions(['Login']),
    async procesarFormulario () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('Validar formulario')
      } else {
        const res = await this.Login({ usuario: this.form.dni, contrasena: this.form.pass })
        if(res == 'error') {
          this.$bvModal.msgBoxOk('Usuario inválido', {
            title: 'Hunter',
            centered: true
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
    @import '../assets/scss/Login/style.scss';
</style>
